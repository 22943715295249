import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import logo from '../Assets/TLRRounded.svg';



const Footer = () => {
  
  return (
    <div>
  
        <footer>
        <Container className='d-flex justify-content-center' data-aos="fade-up">
          <Row className='justify-content-center text-center footerTop py-3'>
            <Col md={8}>
              <h5 className='mb-3 mb-md-0'>Have an idea ? Lets make it happen 
                <Icon.ArrowRight className='mx-2'/> </h5>
            </Col>
            <Col md={3}>
            <a href='tel:9322117686'>
              <Button size="sm" className='callNowBtn mx-auto'>
                <Icon.Telephone size={14} className='me-2' style={{rotate:'12deg'}}/>Call Us Now
              </Button>
            </a>
            </Col>
          </Row>
        </Container>

        <Container className='mt-5 mb-0'>
           <Row data-aos="zoom-in">

            <Col md={4} className='mb-3 mb-md-0 text-center'>
              <img src={logo} className='mb-3' alt='logo'/>
              {/* <h5 className='d-none d-md-block'>" On a mission to build a healthy tribe "</h5> */}
              <p>We are your brand builders ensuring effective content, creative campaigns & flawless technical solutions.</p>
            </Col>

            <Col md={2} className='mb-5 mb-md-0'>
              <h6><Icon.BookmarkHeart className='me-2'/>OUR SOCIALS</h6>
                <div className='sitemap ps-4 mt-3'>
                <a href="https://www.instagram.com/thelastradio.in" rel="noreferrer" target="_blank">
                  <Icon.Instagram className='me-2' size={20}/>Instagram</a>
                <a href='https://www.facebook.com/profile.php?id=61555533585691' rel="noreferrer" target="_blank"><Icon.Facebook className='me-2' size={20}/>Facebook</a>
                <a href='https://www.linkedin.com/company/the-last-radio/' rel="noreferrer" target="_blank"><Icon.Linkedin className='me-2' size={20}/>LinkedIn</a>
                </div>
            </Col>

            <Col md={5} className='mb-4 mb-md-0'>
            <h6><Icon.ChatLeftText className='me-2'/>CONTACT US</h6>
              <div className='sitemap ps-4 mt-3'>
              <a href="https://maps.app.goo.gl/6nQCfi2jwihrbPqG9" rel="noreferrer" target="_blank"> 
                    <div>
                      <div><Icon.GeoAlt className='me-2' size={21}/></div>
                     <div><p>3rd floor Veda Srinivas, New Manish Nagar, Nagpur, Maharashtra - 440025</p></div>
                  </div>
                  </a>

                <a href="tel:9322117686" rel="noreferrer" target="_blank">
                  <div>
                    <Icon.Telephone className='me-2' size={19} style={{rotate:'12deg'}}/>
                     +91 93221 17686
                  </div>
                </a>

                <a href="mailto:admin@thelastradio.in" rel="noreferrer" target="_blank">
                  <div>
                    <Icon.Envelope className='me-2' size={20}/>
                    admin@thelastradio.in
                  </div>
                </a>
                <div>
                  
                </div>
              </div>   
            </Col>

           </Row>
            <h6 className='text-center mt-0 mt-md-3 mb-0'>&copy; {new Date().getFullYear()} | All Right Reserved | The Last Radio </h6>
           </Container>
        </footer>
    </div>
  )
}

export default Footer