import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import MainLayout from "./Layout/MainLayout";
import PetExpo from "./Components/PetExpo";
import ScrollToTop from "./Common/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<MainLayout/>}>
          <Route path="/" element={<Home/>}/>
          <Route path="/pet-expo" element={<PetExpo/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
