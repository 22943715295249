import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import HeroImg from '../Assets/HeroBanner2.svg';
import DigiExp from '../Assets/DigiExperience.svg';

import Customization from '../Assets/Customization.svg';
import OutOfBox from '../Assets/OutOfBox.svg';
import FutureClock from '../Assets/FutureTimeClock.svg';

import Branding from '../Assets/Branding.png';
import WebDev from '../Assets/WebDev.png';
import SEO from '../Assets/SEO.svg';
import DigiMarketing from '../Assets/DigiMarketingSocials.svg';
import GoogleAds from '../Assets/GoogleAds.svg';
import MetaAds from '../Assets/MetaAds.svg';
import LinkedInAds from '../Assets/LinkedInAds.svg';
import InfluencerMarket from '../Assets/InfluencerMarketing.svg';
import SocialMediaManage from '../Assets/SocialMediaManage.png';
import ContentCreation from '../Assets/ContenCreation.svg';

import Blaide from '../Assets/Clients/Blaide.live.webp';
import Jodhpur from '../Assets/Clients/Jodhpur.png';
import Zeppelin from '../Assets/Clients/Zepplin.png';
import FeedingFar from '../Assets/Clients/Feeding.jpeg';
import TheLaughStore from '../Assets/Clients/TheLaughStore.jpeg';
import AbhiPets from '../Assets/Clients/AbhiPets.png';
import UnderRadar from '../Assets/Clients/UnderRadar.jpg';
import RahulDua from '../Assets/Clients/RahulDua.png';
import Lady from '../Assets/Clients/Lady.jpg';
import GauravKapoor from '../Assets/Clients/GauravKapoor.png';

import Insta from '../Assets/Instagram.svg';
import Fb from '../Assets/Facebook.svg';
import LinkedIn from '../Assets/LinkedIn.svg';

import Expertise from '../Assets/Expertise.svg';
import Strategy from '../Assets/Strategy.svg';
import Result from '../Assets/Result.svg';




const Home = () => {
  return (
    <div className='Home'>

    {/* ------------ Hero Banner ------------------*/}
    <div className='Banner'>
      <div className='top'>
          <h1 data-aos='fade-up' data-aos-delay="100">
            <span>YOUR</span><br/> DIGITAL TECHNOLOGY AND SOCIAL MEDIA<br/>
            <span>GROWTH ENABLERS</span>
          </h1>
          <p className='mt-1 mb-3' data-aos='fade-up' data-aos-delay='200'>We are your brand builders ensuring effective content, creative campaigns & tech solutions.</p>
          <a href='#WhoWe' className='KnowMoreBtn' data-aos='fade-up' data-aos-delay='500'>
                  <Icon.ArrowDownShort size={20} className='me-1'/>Know More
          </a>
      </div>

      <div className='bottom'>
      <img src={HeroImg} alt="The Last Radio Stroy" className='imgBanner'/>
      </div>


    </div>
        {/* <Container fluid style={{height:'100vh', paddingTop:'8vh'}}>
          <Row style={{border:'1px solid blue', height:'92vh'}}>
            <Col xs={12} style={{height:'fitContentt'}}>
            <div className='Heading'>
                  <h1>
                    <span>YOUR</span><br/> DIGITAL TECHNOLOGY AND SOCIAL MEDIA<br/>
                    <span>GROWTH ENABLERS</span>
                  </h1>
              </div> 
              <p className='mt-1 mb-3' data-aos='fade-up' data-aos-delay='200'>We are your brand builders ensuring effective content, creative campaigns & tech solutions.</p>
              <a href='#WhoWe' className='KnowMoreBtn' data-aos='fade-up' data-aos-delay='500'>
                  <Icon.ArrowDownShort size={20} className='me-1'/>Know More
              </a>
            </Col>

            <Col xs={12} className='' style={{border:'10px solid green', }}>
               <img src={HeroImg} alt="The Last Radio Stroy" className='img-fluid'/>
            </Col>
          </Row>
        </Container> */}


      {/* ----------- Who We Are ? ---------------*/}
        <div className='WhoWe py-5' id="WhoWe">
          <Container>
          <h1>WHO WE ARE</h1> 
            <Row className='flex-column-reverse flex-md-row'>
              
              <Col md={7} data-aos='fade-up' data-aos-delay='100'>
                <div className='mt-4 mt-md-0'>
                  <h3>IN AN ERA</h3>
                  <p>where The Last Radios are fading away, businesses worldwide are embracing the swift progression of customized digital solutions. At The Last Radio, we thrive on disruption, draw strength from revolution, and prioritize evolution, while we watch traditional marketing die a glorious death.</p>
                  <p>In the realm of The Ultimate Wireless Transmission, we acknowledge the potency of metamorphosis. As enterprises undergo metamorphic processes, so do we. Our objective is to serve as the instigator for your digital odyssey, seamlessly assimilating avant-garde technologies.</p>
                </div>
              
              <div className='mt-4'>
                <h3>THE LAST RADIO</h3>
                <p>boasts a global client base ranging across various niches while maintaining extraordinary ROI and unparalleled growth. We don’t just provide digital services, we help with solutions customised for your business.</p>
                <p className='mb-0'>In navigating the intricate terrain of contemporary digital marketing, The Ultimate Radiowave comprehends that adhering to a one-size-fits-all methodology falls woefully short. Our distinction lies in transcending traditional services, as we meticulously forge bespoke resolutions crafted to catapult your enterprise into the expanse of the digital realm.</p>
              </div>
              </Col>

              <Col md={5} className='h-100 d-flex justify-content-center align-items-center' data-aos="zoom-in" data-aos-delay="200">
                <img src={DigiExp} alt="Who we are" className='img-fluid mt-0 mt-md-4'/>
              </Col>
            </Row>
          </Container>
        </div>

        {/* ----------- Features (Why we stand out) ---------------- */}
        <div className='features py-5'>
          <Container>
          <h1>OUR CREATIVE TEAM DELIVERS, </h1>
          <Row className='gx-5 mb-2'>
            <Col md={4} className='mb-5 mb-md-0' data-aos="fade-up" data-aos-delay="100">
              <Card>
                  <img src={Customization} alt="Customised Marketing Solutions"/>
                  <h5>CUSTOMISED MARKETING SOLUTIONS</h5>
                  <p>Our analytical prowess to understand your digital presence, provide a customised solution basis your needs and execute it like a pro using unconventional marketing ways, is what seperates us from the bunch.</p>
              </Card>
            </Col>

            <Col md={4} className='mb-5 mb-md-0' data-aos="fade-up" data-aos-delay="200">
              <Card>
                  <img src={OutOfBox} alt="Out Of The Box Solutions"/>
                  <h5>OUT OF THE BOX SOLUTIONS</h5>
                  <p>Distinctive strategies to optimise your brand's marketing efforts to get efficient results with reduced ad budget.</p>
              </Card>
            </Col>
            
            <Col md={4} data-aos="fade-up" data-aos-delay="300">
              <Card>
                  <img src={FutureClock} alt="Future-ready marketing solutions"/>
                  <h5>FUTURE READY MARKETING SOLUTIONS</h5>
                  <p>We design strategies which help your bsuiness get constant results in dynamic digital landscape.</p>
              </Card>
            </Col>

          </Row>
          </Container>
        </div>

      {/*------------------ Services ------------------*/}
      <div className='services py-5'>
        <Container>
          <h1 className='mb-3'>OUR SERVICES</h1>
          <Row className='justify-content-center gy-5' data-aos='fade-up'>
            <Col md={3}>
              <img src={Branding} alt="Branding"/>
              <h4>BRANDING</h4>
            </Col>

            <Col md={3}>
              <img src={WebDev} alt="Web Developement"/>
              <h4>WEB DEVELOPMENT</h4>
            </Col>

            <Col md={3}>
              <img src={SEO} alt="SEO"/>
              <h4>SEO</h4>
            </Col>

            <Col md={3}>
              <img src={DigiMarketing} alt="Digital Marketing"/>
              <h4>DIGITAL MARKETING</h4>
            </Col>

            <Col md={3}>
              <img src={GoogleAds} alt="Google Ads"/>
              <h4>GOOGLE ADS</h4>
            </Col>

            <Col md={3}>
              <img src={MetaAds} alt="Facebook Meta Ads"/>
              <h4>FACEBOOK META ADS</h4>
            </Col>

            <Col md={3}>
              <img src={LinkedInAds} alt="LinkedIn Ads"/>
              <h4>LINKEDIN ADS</h4>
            </Col>

            <Col md={3}>
              <img src={InfluencerMarket} alt="Influencer Marketing"/>
              <h4>INFLUENCER MARKETING</h4>
            </Col>

            <Col md={4}>
              <img src={SocialMediaManage} alt="Social Media Management"/>
              <h4>SOCIAL MEDIA MANAGEMENT</h4>
            </Col>

            <Col md={4}>
              <img src={ContentCreation} alt="Content Creation"/>
              <h4>CONTENT CREATION - FB, YT, ETC</h4>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='Thread pt-5 pb-0 pb-md-2'>
        <Container>
          <h1>WHY CHOOSE OUR INSTAGRAM THREADS MARKETING SERVICE ?</h1>
          <p className="text-center">Elevate your brand's story, captivate your audience, and dominate the digital landscape with our Instagram Threads Marketing service. <br className="d-none d-md-block"/> Let us thread the path to success for your brand!</p>
          <Row>
            <Col md={4} className='mb-5 mb-md-0' data-aos="fade-up" data-aos-delay='100'>
              <Card>
                  <img src={Expertise} alt="Out Of The Box Solutions"/>
                  <h5>EXPERTISE</h5>
                  <p>Our team comprises seasoned professionals with a proven track record in Instagram marketing.</p>
              </Card>
            </Col>

            <Col md={4} className='mb-5 mb-md-0' data-aos="fade-up" data-aos-delay='200'>
              <Card>
                  <img src={Strategy} alt="Out Of The Box Solutions"/>
                  <h5>TAILORED STRATEGIES</h5>
                  <p>We understand that every brand is unique. Our strategies are customized to align with your brand's personality and goals.</p>
              </Card>
            </Col>

            <Col md={4} className='mb-5 mb-md-0' data-aos="fade-up" data-aos-delay='300'>
              <Card>
                  <img src={Result} alt="Out Of The Box Solutions"/>
                  <h5>MEASURABLE RESULTS</h5>
                  <p>Track the impact of our service through detailed analytics, witnessing the growth in your brand's Instagram presence.</p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='clients py-5'>
        <Container>
            <h1>SOME BRANDS FROM OUR FAMILY</h1>
            <div class="logos">
              <div class="logos-slide">
                <img src={Blaide} alt="Clients"/>
                <img src={Jodhpur} alt="Clients"/>
                <img src={Zeppelin} alt="Clients"/>
                <img src={FeedingFar} alt="Clients"/>
                <img src={TheLaughStore} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={AbhiPets} alt="Clients"/>
                <img src={UnderRadar} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={RahulDua} alt="Clients"/>
                <img src={Lady} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={GauravKapoor} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={Blaide} alt="Clients"/>
                <img src={Jodhpur} alt="Clients"/>
                <img src={Zeppelin} alt="Clients"/>
                <img src={FeedingFar} alt="Clients"/>
                <img src={TheLaughStore} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={AbhiPets} alt="Clients"/>
                <img src={UnderRadar} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={RahulDua} alt="Clients"/>
                <img src={Lady} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={GauravKapoor} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={Blaide} alt="Clients"/>
                <img src={Jodhpur} alt="Clients"/>
                <img src={Zeppelin} alt="Clients"/>
                <img src={FeedingFar} alt="Clients"/>
                <img src={TheLaughStore} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={AbhiPets} alt="Clients"/>
                <img src={UnderRadar} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={RahulDua} alt="Clients"/>
                <img src={Lady} style={{borderRadius:'50%'}} alt="Clients"/>
                <img src={GauravKapoor} style={{borderRadius:'50%'}} alt="Clients"/>
              </div>
            </div>
        </Container>
      </div>

      <div className='socials pb-5 mb-5'>
        <Container>
          <h1>STAY CONNECTED WITH US</h1>
          <p>Click below to follow our social handles for exciting offer alerts and service updates</p>
          <div className='icons'>
            <a href='https://www.instagram.com/thelastradio.in' target="_blank" rel="noreferrer"
                 data-aos='fade-right'>
              <img src={Insta} alt="Instagram - thelastradio.in"/> </a>
            <a href='https://www.facebook.com/profile.php?id=61555533585691' target="_blank" rel="noreferrer">
              <img src={Fb} alt="Facebook - thelastradio.in" rel="noreferrer"/> </a>
            <a href='https://www.linkedin.com/company/the-last-radio' target="_blank" rel="noreferrer" data-aos='fade-left'>
              <img src={LinkedIn} alt="Linked - thelastradio.in"/> </a>
          </div>
          {/* <p className='label'>*Click on images to navigate</p> */}
        </Container>
      </div>
    </div>
  )
}

export default Home;