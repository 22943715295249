import React from 'react';
import { Outlet } from 'react-router-dom';
import Menubar from '../Common/Menubar';
import Footer from '../Common/Footer';

const MainLayout = () => {
  return (
    <>
    <Menubar/>
    <div style={{paddingTop:'8vh'}}>
      <Outlet/>
    </div>
    <Footer/>
    </>
  )
}

export default MainLayout