import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import TLRRectangleBLK from '../Assets/TLRRectangleBLK.svg';
import TLRRectangleYLW from '../Assets/TLRRectangleYLW.svg';
import * as Icon from 'react-bootstrap-icons';
import Insta from '../Assets/Instagram.svg';
import Fb from '../Assets/Facebook.svg';
import LinkedIn from '../Assets/LinkedIn.svg';
import { useNavigate } from 'react-router-dom';


const Menubar = () => {

  const navigate = useNavigate();

 
  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }


  useEffect(()=>{
    changeNavbarColor();
  },[])

  const [navScrollDown, setNavScrollDown] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 60){
      setNavScrollDown(true);
     }
     else{
      setNavScrollDown(false);
     }
  }
  window.addEventListener('scroll', changeNavbarColor);


 
  return (

    <nav className={navScrollDown ? 'navbarBlack' : 'navbarTransperent'}>
    <img src={navScrollDown ? TLRRectangleYLW : TLRRectangleBLK} alt="The Last Radio" 
        className='logo' onClick={()=>navigate('/')}/>
          <div className='socials'>
            <a className={navScrollDown ? 'text-white qrBtn me-3' : 'text-black qrBtn me-3'}
                onClick={()=>navigate('/pet-expo')}>Pet Expo</a>
            <a href='https://www.instagram.com/thelastradio.in' target="_blank" rel="noreferrer">
              <img src={Insta} alt="Instagram - thelastradio.in"/> </a>
            <a href='https://www.facebook.com/profile.php?id=61555533585691' target="_blank" rel="noreferrer">
              <img src={Fb} alt="Facebook - thelastradio.in"/> </a>
            <a href='https://www.linkedin.com/company/the-last-radio' target="_blank" rel="noreferrer">
              <img src={LinkedIn} alt="Linked - thelastradio.in"/> </a>
          </div>

        {/* <div className='d-flex justify-content-center align-items-center'>
            <Icon.GeoAltFill size={24} className='me-3'/>
            <Icon.QrCode size={24} className='me-3'/>
            <Icon.Instagram size={24} className='me-2'/> 
            <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false" onClick={setnav}>
                <div className='menu-btn__burger'></div>
            </div>
        </div> */}
       

      {/* <ul className="lg-nav">
          <h1>Lg Nav</h1>
      </ul> */}

    <Container fluid className="mobile-nav"  id="selectNav" data-visible="false">
        <Row >
            {/* <Col md={5} className="p-0 d-none d-md-block">
                <img src={TLRRectangle} className="img-fluid" alt="Item-1"/>
            </Col> */}
            <Col md={7} className="d-flex justify-content-center align-items-center mobile-nav-links" style={{height:'100vh'}}>
                <div className='d-flex flex-column'>
                <a to="/"><h3 onClick={setnav}><span>01</span>HOME</h3></a>
                <a to="/projects"><h3 onClick={setnav}><span>02</span>ABOUT</h3></a>
                <a to="/about"><h3 onClick={setnav}><span>03</span>GALLERY</h3></a>
                <a to="/about"><h3 onClick={setnav}><span>04</span>SERVICES</h3></a>
                <a to="/contact"><h3 onClick={setnav}><span>05</span>CONTACT</h3></a>
                </div>
            </Col>
        </Row>
    </Container>

    </nav>
  )
}

export default Menubar;