import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Accordion, Button, Card, Col, Container, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap';
import PetExpoMapLarge from '../Assets/PetExpoMapLarge.svg';
import * as Icon from 'react-bootstrap-icons';
import Gallery from '../Assets/PetGallery.svg';

import Dog from '../Assets/Scrolls/Dog.svg';
import Cat from '../Assets/Scrolls/Cat.svg';
import Rabbit from '../Assets/Scrolls/Rabbit.svg';
import Bird1 from '../Assets/Scrolls/Bird1.svg';
import Bird2 from '../Assets/Scrolls/Bird2.svg';
import Nest from '../Assets/Scrolls/Nest.svg';
import Fish from '../Assets/Scrolls/Fish.svg';
import Bull from '../Assets/Scrolls/Bull.svg';
import Snake from '../Assets/Scrolls/Snake.svg';
import Frog from '../Assets/Scrolls/Frog.svg';


import DogRing from '../Assets/ExpoCards/DogRing.svg';
import PetFashion from '../Assets/ExpoCards/PetFashion.svg';
import PetAdoption from '../Assets/ExpoCards/Adoption.svg';
import DJ from '../Assets/ExpoCards/DJ.svg';



const PetExpo = () => {

    const today = new Date();
    const date = today.getDate()+'/'+ today.getMonth()+1 +'/'+ today.getFullYear()+' - '+ today.getHours()+':'+ today.getMinutes();
  

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');

    const [nameErr, setNameErr] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);

    const [loader, setLoader] = useState(false);

    const validate =()=>{
        let isValid = true;

        if(!name){
            isValid = false;
            setNameErr(true); 
        }
        else if(!mobile || mobile.length<10){
            isValid = false;
            setMobileErr(true);
        }
        else if(!email || !email.includes('@') || !email.includes('.')){
            isValid = false;
            setEmailErr(true);
        }
        return isValid;
    }
    
    const handleFormSheet = async(e)=>{
        e.preventDefault();
        const payload ={ name, mobile, email, date}
        if(validate()){
            setLoader(true);
            await fetch('https://sheet.best/api/sheets/05fe883c-3c10-497e-817c-4b66f7bfbce5', {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify(payload)
            }).then((response) => {
                toast.success("Submitted Successfully !");
                setName('');
                setMobile('');
                setEmail('');
                setLoader(false);
			}).catch((Err)=>{
                console.log("Err", Err);
                toast.error("Failed to submit, Please try again");
                setLoader(false);
            })
        }
    }


  return (
    <div className='PetExpo'>
        <ToastContainer/>

            <div className='map'>   
                <Container>
                    <Row className='justify-content-center align-items-center'>
                    <h1>PET EXPO EVENT MAP</h1>
                        <Col lg={1} className='mb-4 mb-md-0'>
                            <div className='left'>
                                <div className='leftScroll d-flex flex-row flex-lg-column'>
                                <img src={Dog} alt="Dog"/>
                                <img src={Cat} alt="Cat"/>
                                <img src={Fish} alt="Fish"/>
                                <img src={Frog} alt="Frog"/>
                                <img src={Bird1} alt="Bird"/>
                                <img src={Snake} alt="Snake"/>
                                <img src={Bird2} alt="Bird2"/>
                                <img src={Nest} alt="Nest"/>
                                <img src={Bull} alt="Bull"/>
                                <img src={Rabbit} alt="Rabbit"/>
                            </div>
                            </div>
                        </Col>

                        <Col lg={9}>
                        <img src={PetExpoMapLarge} alt="Pet Expo Event Map" className='img-fluid'/>
                        </Col>

                        <Col lg={1} className='mt-4 mt-md-0'>
                        <div className='right'>
                                <div className='rightScroll d-flex flex-row-reverse flex-lg-column-reverse'>
                                <img src={Dog} alt="Dog"/>
                                <img src={Cat} alt="Cat"/>
                                <img src={Fish} alt="Fish"/>
                                <img src={Frog} alt="Frog"/>
                                <img src={Bird1} alt="Bird"/>
                                <img src={Snake} alt="Snake"/>
                                <img src={Bird2} alt="Bird2"/>
                                <img src={Nest} alt="Nest"/>
                                <img src={Bull} alt="Bull"/>
                                <img src={Rabbit} alt="Rabbit"/>
                            </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        {/*---------------- Clients Registration Data for Images ------------ */}
            <div className='Register mt-5'>
                <Container fluid className='ps-0'>
                    <Row className='align-items-center'>
                        <Col md={6} className='p-0 d-none d-md-block'>
                            <img src={Gallery} alt="gallery" className='img-fluid'/>
                        </Col>

                        <Col md={6} className='p-5 p-md-0 d-flex justify-content-center align-items-center'>
                            <div>
                            <h1 data-aos="fade-up">GET EXCLUSIVE PET-EXPO GALLERY <br className='d-none d-md-block'/> ON YOUR INBOX</h1>
                            <p data-aos="fade-up" style={{textAlign:'center', marginTop:'-3px', marginBottom:'0px'}}>
                            <Icon.Send className='me-1' size={15}/>Submit details below to get notified about events images and updates</p>
                            <Form className='mt-4 px-0 px-md-5'>

                                    <FloatingLabel label="Your Name" className="mb-4" data-aos="fade-up" dat-aos-delay="100">
                                    <Form.Control placeholder='Name' value={name} onChange={(e)=> {if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\]|/*.//\-/?<>,;`'""/]/) &&
                                                        e.target.value.trim() ) {
                                                            setName(e.target.value);
                                                        } else if (e.target.value.length === 0) {
                                                            setName(e.target.value);
                                                        }
                                    e.target.value.length > 0 ? setNameErr(false) : setNameErr(true); }} />
                                    {nameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                                    </FloatingLabel>
                                

                                    <FloatingLabel label="Mobile Number" className="mb-4" data-aos="fade-up" dat-aos-delay="200">
                                        <Form.Control placeholder='Mobile Number' value={mobile} onChange={(e) => { 
                                            if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                                                setMobile(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setMobile(e.target.value);
                                            }
                                            e.target.value.length > 0 ? setMobileErr(false) : setMobileErr(true);
                                        }} />
                                        {mobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
                                    </FloatingLabel>

                                    <FloatingLabel label="Email ID" className="mb-3" data-aos="fade-up" dat-aos-delay="300">
                                    <Form.Control placeholder="Email ID" value={email} onChange={(e)=>{ setEmail(e.target.value);
                                    e.target.value.length>0 ? setEmailErr(false): setEmailErr(true); } }/>
                                    {emailErr ? <p className='errMsg'>Please enter valid email id</p> : ''}
                                    </FloatingLabel>

                                    {
                                        loader ? <center><Spinner variant='dark'/></center> :
                                        <Button className='callNowBtn mx-auto' onClick={handleFormSheet} data-aos="fade-up" dat-aos-delay="400">
                                        <Icon.Send className='me-2'/>Submit
                                    </Button>
                                    }
                            </Form>
                        </div>
                        </Col>

                    </Row>
                </Container>
            </div>

        <div className='ExpoCards mt-5'>
            <Container>
                <h1>SOME EVENT ATTRACTIONS</h1>
                <Row className='gy-5 gx-5'>
                    <Col  md={4} lg={4}>
                        <Card>
                            <img src={DogRing} alt="Professional Dog Show"/>
                            <h5>PROFESSIONAL DOG SHOW</h5>
                            <p>Get ready to witness the canine equivalent of a red carpet event! Our professional dog show will feature the city's top-notch furry talent strutting their stuff, competing for titles like "Best in Show," "Most Obedient," and even "Most Enthusiastic Tail Wag." Be prepared to be amazed as our four-legged stars showcase their skills, charm, and charisma in a paw-some spectacle !</p>
                        </Card>
                    </Col>
                    
                    <Col  md={4} lg={4}>
                        <Card>
                            <img src={Snake} alt="Exotic Pet Expo"/>
                            <h5>EXOTIC PET EXPO</h5>
                            <p>Ever seen a sugar glider or a bearded dragon up close? Now's your chance! The Exotic Pet Expo is a zoological wonderland where you can meet and greet creatures that redefine the word "unique." From slithery serpents to fluffy ferrets, this expo is a front-row seat to the animal kingdom's unconventional cast. Who knows, you might just find your next exotic pet companion !</p>
                        </Card>
                    </Col>

                    <Col  md={4} lg={4}>
                        <Card>
                            <img src={PetFashion} alt="Pet Fashion Show"/>
                            <h5>PET FASHION SHOW</h5>
                            <p> Move over, Paris Fashion Week! Our furry fashionistas are ready to steal the spotlight at the Pet Fashion Show. Expect a parade of the latest trends in canine couture, with pups rocking everything from dapper bow ties to glamorous tutus. It's a fashion-forward extravaganza that will leave you barking for more style inspiration.</p>
                        </Card>
                    </Col>

                    <Col  md={4} lg={4}>
                        <Card>
                            <img src={PetAdoption} alt="Pet Adoption Camp"/>
                            <h5>PET ADOPTION CAMP</h5>
                            <p>Love knows no breed! Our Pet Adoption Camp is where you can find your new best friend. Furry faces with wagging tails and a heart full of love await their forever homes. Adopt, don't shop, and experience the joy of giving a deserving pet a second chance at a happy life.</p>
                        </Card>
                    </Col>

                    <Col  md={4} lg={4}>
                        <Card>
                            <img src={DJ} alt="DJ STAGE"/>
                            <h5>DJ STAGE</h5>
                            <p>Shake a leg, or should we say, a paw! The DJ Stage is where the party animals come to groove. Whether you're a two-legged or a four-legged dancer, the beats will be infectious, and the energy will be through the woof! Let's see whose tail wags the most on the dance floor.</p>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>


        {/* -------------  The Pack FAQ Section ------------------*/}
        <div className='faq'>
        <Container className='mt-5 mb-5 pb-5 pb-md-4'>
        <Row className='justify-content-center text-center'>
        <h1>FREQUENTLY ASKED QUESTIONS ?</h1>
        <Col xs={11} md={12} className='mt-3'>
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-delay="100">
                <Accordion.Header>Can I bring my own pet to the event ?</Accordion.Header>
                <Accordion.Body>
                <Icon.ArrowRight className='me-2'/>Yes, absolutely! We encourage pet owners to bring their furry friends and share in the fun.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-delay="200">
                <Accordion.Header>Is there an entrance fee for the event ?</Accordion.Header>
                <Accordion.Body>
                <Icon.ArrowRight className='me-2'/>Admission is free! However, some activities and stalls may have separate charges.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-delay="300">
                <Accordion.Header>Can I adopt a pet on the spot at the Pet Adoption Camp ?</Accordion.Header>
                <Accordion.Body>
                <Icon.ArrowRight className='me-2'/>Yes, you can! Our adoption process is designed for you to find your new companion on the same day.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-delay="300">
                <Accordion.Header>Are the pet-friendly treats at the canteen safe for all pets ? </Accordion.Header>
                <Accordion.Body>
                <Icon.ArrowRight className='me-2'/>Absolutely! Our treats are carefully selected to be safe and delicious for a variety of pets.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-delay="300">
                <Accordion.Header>Can I participate in the DJ Stage dance-off with my pet ?</Accordion.Header>
                <Accordion.Body>
                <Icon.ArrowRight className='me-2'/>Of course! It's a fun and inclusive atmosphere, so feel free to showcase your dance moves with your furry dance partner.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            </Col>
            </Row>
        </Container>
        </div>



        {/* <img src={petExp} width={800}/> */}
    </div>
  )
}

export default PetExpo